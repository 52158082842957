import Button from "@mui/material/Button";
import baseballImage from "./img/Baseball.svg";
import "./Navbar.css";
import About from "./components/about/About";
import { useState } from "react";
import Preferences from "./components/preferences/Preferences";

export default function Navbar({preferences, setPreferences}) {
    // about modal
    const [aboutOpen, setAboutOpen] = useState(false);
    const handleAboutOpen = () => setAboutOpen(true);
    const handleAboutClose = () => setAboutOpen(false);
    
    // preferences modal
    const [prefOpen, setPrefOpen] = useState(false);
    const handlePrefOpen = () => setPrefOpen(true);
    const handlePrefClose = () => setPrefOpen(false);

    return (
        <div className="navbar" position="static">
            <div className="row">
                <img src={baseballImage} className="App-logo" alt="logo" />
                <p>MLB Gameday Scoreboard</p>
            </div>
            <div className="row">
                <Button
                    href="https://www.linkedin.com/in/claycotton/"
                    color="inherit">
                    <p className="button-text">LinkedIn</p>
                </Button>

                <Button href="https://github.com/claycot" color="inherit">
                    <p className="button-text">GitHub</p>
                </Button>

                <Button onClick={handleAboutOpen} color="inherit">
                    <p className="button-text">About</p>
                </Button>
                <About open={aboutOpen} handleClose={handleAboutClose} />

                <Button onClick={handlePrefOpen} color="inherit">
                    <p className="button-text">⚙</p>
                </Button>
                <Preferences open={prefOpen} handleClose={handlePrefClose} preferences={preferences} setPreferences={setPreferences}></Preferences>
            </div>
        </div>
    );
}
