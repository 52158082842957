import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
};

export default function Preferences({
    open,
    handleClose,
    preferences,
    setPreferences,
}) {
    const handleChange = (event) => {
        setPreferences({
            ...preferences,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard">
                        <FormLabel component="legend">
                            Display Preferences
                        </FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferences.playerNumbers}
                                        onChange={handleChange}
                                        name="playerNumbers"
                                    />
                                }
                                label="Player Number on Base"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferences.stateIndicator}
                                        onChange={handleChange}
                                        name="stateIndicator"
                                    />
                                }
                                label="Data Age Indicators"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferences.flashUpdate}
                                        onChange={handleChange}
                                        name="flashUpdate"
                                    />
                                }
                                label="Flash on Update"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferences.popupDelete}
                                        onChange={handleChange}
                                        name="popupDelete"
                                    />
                                }
                                label="Popup Notification on Delete"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferences.darkMode}
                                        onChange={handleChange}
                                        name="darkMode"
                                    />
                                }
                                label="Dark Mode"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}
