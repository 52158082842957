import React, { useState, useEffect, useRef } from 'react';
import Scoreboard from './Scoreboard';
import { fetchGamesInit, fetchUpdates } from './utils';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { toast, Toaster } from 'react-hot-toast';
import { useCookies } from 'react-cookie';

function App() {
  const [response, setResponse] = useState({metadata: null, data: new Map()});
  const [cookies, setCookie] = useCookies(['preferences']);

  // load prefs from cookie if available, or use defaults
  const [preferences, setPreferences] = useState(() => { 
    return cookies.preferences || 
    {
      "playerNumbers": true,
      "stateIndicator": false,
      "flashUpdate": false,
      "popupDelete": true,
      "darkMode": false,
    }
  });

  // maintain user preferences with a cookie
  useEffect(() => {
    // delay a second in case the user is changing a bunch of prefs quickly
    const handler = setTimeout(() => {        
      // set cookie to expire in 6 months
      const cookieDate = new Date();
      cookieDate.setMonth(cookieDate.getMonth() + 6);

      setCookie(
        'preferences', 
        preferences, 
        { path: '/', expires: cookieDate }
      );
    }, 1000);
  
    return () => clearTimeout(handler);
  }, [preferences, setCookie]);

  const fetchCalled = useRef(false);

  // fetch and update games
  useEffect(() => {
    if (!fetchCalled.current) {
      // initial fetch
      fetchGamesInit(setResponse);
      // listen for updates with SSE
      fetchUpdates(setResponse, toast);
      fetchCalled.current = true;
    }
  }, []);

  return (
    <div className={"App " + (preferences.darkMode ? "dark-mode" : "")}>
      <Navbar preferences={preferences} setPreferences={setPreferences}/>
      {preferences.popupDelete ? <Toaster /> : "" }
      <div className="center-content">
        <h3>
          {response && response.metadata ? (
            <div>
              <span>Game Date:&nbsp;{new Date(response.metadata.timestamp).toLocaleDateString()}</span>
              <span>&nbsp;·&nbsp;</span>
              <span>Refreshed:&nbsp;{new Date(response.metadata.timestamp).toLocaleTimeString()}</span>
            </div>
          ) : null}
        </h3>
        {response && response.data ? <Scoreboard data={Array.from(response.data.values())} preferences={preferences} /> : "Loading..."}
      </div>
      <Footer />
    </div>
  );
}

export default App;