import { useEffect, useState } from "react";

export async function fetchGamesInit(setData) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_ADDRESS}/api/games/initial`
        );
        const data = await response.json();
        const dataMap = new Map();
        if (data.data) {
            data.data.forEach((game) => {
                dataMap.set(game.id, game);
            });
        }
        data.data = dataMap;
        setData(data);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

export function fetchUpdates(setData, toastSender) {
    try {
        const updates = new EventSource(
            `${process.env.REACT_APP_BACKEND_ADDRESS}/api/games/update`
        );

        updates.onopen = () => {
            console.log(
                `SSE connection established. Listening for game updates.`
            );
        };

        updates.addEventListener("keep-alive", (_) => {
            // console.log("keep-alive!");
        });

        updates.addEventListener("add", (event) => {
            // console.log("add!");

            const { metadata, data } = JSON.parse(event.data);

            setData((state) => {
                data.forEach((game) => {
                    // toastSender(`New Game: ${game.state.teams.away.info.abbreviation} @ ${game.state.teams.home.info.abbreviation}`);
                    state.data.set(game.id, game);
                });

                return {
                    metadata,
                    data: state.data,
                };
            });
        });

        updates.addEventListener("update", (event) => {
            // console.log("update!");

            const { metadata, data } = JSON.parse(event.data);

            setData((state) => {
                data.forEach((game) => {
                    // toastSender(`Game Updated: ${game.state.teams.away.info.abbreviation} @ ${game.state.teams.home.info.abbreviation}`);
                    state.data.set(game.id, game);
                });

                return {
                    metadata,
                    data: state.data,
                };
            });
        });

        updates.addEventListener("remove", (event) => {
            // console.log("remove!");

            const { metadata, data } = JSON.parse(event.data);

            setData((state) => {
                data.forEach((gameID) => {
                    toastSender(
                        `Game Deleted: ${state.data.get(gameID).state.teams.away.info.abbreviation} @ ${state.data.get(gameID).state.teams.home.info.abbreviation}`
                    );
                    state.data.delete(gameID);
                });

                return {
                    metadata,
                    data: state.data,
                };
            });
        });

        updates.addEventListener("fail", (event) => {
            console.log("fail!");
            console.log(event);
        });

        updates.onerror = (err) => {
            console.log(err);
        };
    } catch (error) {
        console.error("Error establishing SSE connection:", error);
    }
}

export function getScore(item) {
    let score = {
        away: null,
        home: null,
    };
    switch (item.state.status.general) {
        case "Preview":
            // leave the score null
            break;
        case "Live":
            // update the score
            score.away = item.state.teams.away.score.toString();
            score.home = item.state.teams.home.score.toString();
            break;
        case "Final":
            if (item.state.status.detailed !== "Postponed") {
                // update the score
                score.away = item.state.teams.away.score.toString();
                score.home = item.state.teams.home.score.toString();
            }
            break;
        default:
            // leave the score null
            break;
    }
    return score;
}

export function getInning(item) {
    let inning = {
        top_bottom: null,
        number: null,
    };
    switch (item.state.status.general) {
        case "Preview":
            // leave the inning null
            break;
        case "Live":
            // update the inning
            inning.top_bottom = item.state.inning.top_bottom;
            inning.number = item.state.inning.number;
            break;
        case "Final":
            // update the inning
            inning.number = "f";
            break;
        default:
            // leave the inning null
            break;
    }
    return inning;
}

// custom hook to return true for duration (ms) if data in watch has changed
export function useHasRecentlyChanged(watch, duration = 2000) {
    const [hasRecentlyChanged, setHasRecentlyChanged] = useState(false);

    useEffect(() => {

        setHasRecentlyChanged(true);

        const timer = setTimeout(() => {
            setHasRecentlyChanged(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [watch, duration]);

    return hasRecentlyChanged;
}
