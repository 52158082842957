import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import exampleImage from "../../img/example.jpg";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
};

export default function About({ open, handleClose }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Who am I?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        I'm Clay! I'm a software engineer and a baseball
                        fanatic. If you'd like to know more about me, please
                        visit my LinkedIn or GitHub pages (linked in the
                        navigation bar).
                    </Typography>

                    <br />

                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        What is this?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        This page displays a live scoreboard for today's Major
                        League Baseball (MLB) games. It is designed to recreate
                        the out-of-town scoreboards that many MLB stadiums use,
                        specifically those used by the Philadelphia Phillies in
                        Citizens Bank Park between 2004 and 2024.
                    </Typography>
                    <img
                        src={exampleImage}
                        style={{
                            maxWidth: "100%",
                            height: "auto",
                            display: "block",
                            margin: "16px auto",
                        }}
                        alt="Phillies out-of-town scoreboards circa 2004"></img>
                </Box>
            </Modal>
        </div>
    );
}
