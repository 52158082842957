import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Display as SegDisplay } from "@claycot/react-7-segment-display";
import { getScore, getInning, useHasRecentlyChanged } from "./utils";
import "./Diamond.css";

function Gameboard({ item, index, width, preferences }) {
    // track if the game data has recently changed
    const hasRecentlyChanged = useHasRecentlyChanged(item.state, 1500);

    const widthPx = width + "px";
    return (
        <div
            key={index}
            className={"gameboard " + (preferences.flashUpdate && hasRecentlyChanged ? "flash" : "")}
            style={{ "--gameboard-width": widthPx }}>
            <div className="gameboardContents">
                <div className="pitcherBox">
                    <Tooltip
                        title={item.state.teams.away.pitcher.name}
                        placement="bottom"
                        enterTouchDelay={0}
                        arrow>
                        <div>
                            <SegDisplay
                                value={
                                    item.state.teams.away.pitcher.number !==
                                    "-1"
                                        ? item.state.teams.away.pitcher.number
                                        : null
                                }
                                color="orange"
                                height={width / 9}
                                leadingZeroes={false}
                                paddingInner="0px"
                                paddingOuter="0px"
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={item.state.teams.home.pitcher.name}
                        placement="top"
                        enterTouchDelay={0}
                        arrow>
                        <div>
                            <SegDisplay
                                value={
                                    item.state.teams.home.pitcher.number !==
                                    "-1"
                                        ? item.state.teams.home.pitcher.number
                                        : null
                                }
                                color="orange"
                                height={width / 9}
                                leadingZeroes={false}
                                paddingInner="0px"
                                paddingOuter="0px"
                            />
                        </div>
                    </Tooltip>
                </div>
                <div className="diamondBox">
                    <div className="diamond">
                        <div className="bases">
                            <div className="first"></div>
                            <div className="second"></div>
                            <div className="third"></div>
                            <div className="home"></div>
                        </div>
                        <div className="runners">
                            <Tooltip
                                className="not-selectable"
                                title={
                                    item.state.diamond.first.number !== "-1"
                                        ? item.state.diamond.first.name
                                        : ""
                                }
                                placement="left"
                                enterTouchDelay={0}
                                arrow>
                                <div
                                    className={
                                        "circle first " +
                                        (item.state.diamond.first.number !==
                                        "-1"
                                            ? "green"
                                            : "")
                                    }>
                                    <p>
                                        {preferences.playerNumbers &&
                                        item.state.diamond.first.number !== "-1"
                                            ? item.state.diamond.first.number
                                            : ""}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip
                                className="not-selectable"
                                title={
                                    item.state.diamond.second.number !== "-1"
                                        ? item.state.diamond.second.name
                                        : ""
                                }
                                placement="bottom"
                                enterTouchDelay={0}
                                arrow>
                                <div
                                    className={
                                        "circle second " +
                                        (item.state.diamond.second.number !==
                                        "-1"
                                            ? "green"
                                            : "")
                                    }>
                                    <p>
                                        {preferences.playerNumbers &&
                                        item.state.diamond.second.number !==
                                            "-1"
                                            ? item.state.diamond.second.number
                                            : ""}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip
                                className="not-selectable"
                                title={
                                    item.state.diamond.third.number !== "-1"
                                        ? item.state.diamond.third.name
                                        : ""
                                }
                                placement="right"
                                enterTouchDelay={0}
                                arrow>
                                <div
                                    className={
                                        "circle third " +
                                        (item.state.diamond.third.number !==
                                        "-1"
                                            ? "green"
                                            : "")
                                    }>
                                    <p>
                                        {preferences.playerNumbers &&
                                        item.state.diamond.third.number !== "-1"
                                            ? item.state.diamond.third.number
                                            : ""}
                                    </p>
                                </div>
                            </Tooltip>
                            <Tooltip
                                className="not-selectable"
                                title={
                                    item.state.diamond.batter.number !== "-1"
                                        ? item.state.diamond.batter.name
                                        : ""
                                }
                                placement="top"
                                enterTouchDelay={0}
                                arrow>
                                <div className={"circle home white"}>
                                    <p>
                                        {preferences.playerNumbers &&
                                        item.state.diamond.batter.number !==
                                            "-1"
                                            ? item.state.diamond.batter.number
                                            : ""}
                                    </p>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="outs">
                            <div
                                className={
                                    "circle home " +
                                    (item.state.outs >= 1 ? "red" : "")
                                }></div>
                            <div
                                className={
                                    "circle home " +
                                    (item.state.outs >= 2 ? "red" : "")
                                }></div>
                        </div>
                        <div className="text">OUTS</div>
                    </div>
                </div>
                <div className="teamBox">
                    <Tooltip
                        title={item.state.teams.away.info.name}
                        placement="bottom"
                        enterTouchDelay={0}
                        arrow>
                        <p>{item.state.teams.away.info.abbreviation}</p>
                    </Tooltip>
                    <Tooltip
                        title={item.state.teams.home.info.name}
                        placement="top"
                        enterTouchDelay={0}
                        arrow>
                        <p>{item.state.teams.home.info.abbreviation}</p>
                    </Tooltip>
                </div>
                <div className="scoreBox">
                    <div>
                        <SegDisplay
                            value={getScore(item).away}
                            color="orange"
                            height={width / 9}
                            blankChar=" "
                            leadingZeroes={false}
                            paddingInner="0px"
                            paddingOuter="0px"
                            rhsOnlyFirstDigit={getScore(item).home < 20 && getScore(item).away < 20}
                        />
                    </div>
                    <div>
                        <SegDisplay
                            value={getScore(item).home}
                            color="orange"
                            height={width / 9}
                            blankChar=" "
                            leadingZeroes={false}
                            paddingInner="0px"
                            paddingOuter="0px"
                            rhsOnlyFirstDigit={getScore(item).home < 20 && getScore(item).away < 20}
                        />
                    </div>
                </div>
                <div className="inningBox">
                    <div>
                        {getInning(item).top_bottom === "Top" ? (
                            <div className="circle inning-marker orange" />
                        ) : (
                            <div className="circle inning-marker" />
                        )}
                    </div>
                    <Tooltip
                        title={item.state.status.detailed}
                        placement="bottom"
                        enterTouchDelay={0}
                        arrow>
                        {getInning(item).number !== null ? (
                            <div>
                                <SegDisplay
                                    value={getInning(item).number}
                                    color="orange"
                                    height={width / 9}
                                    blankChar=" "
                                    leadingZeroes={false}
                                    paddingInner="0px"
                                    paddingOuter="0px"
                                    rhsOnlyFirstDigit={true}
                                />
                            </div>
                        ) : (
                            <div>
                                {new Date(
                                    item.state.status.start_time.dateTime
                                ).toLocaleTimeString("en-US", {
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}
                            </div>
                        )}
                    </Tooltip>
                    <div>
                        {getInning(item).top_bottom === "Bottom" ? (
                            <div className="circle inning-marker orange" />
                        ) : (
                            <div className="circle inning-marker" />
                        )}
                    </div>
                </div>
            </div>
            {preferences.stateIndicator ? (
                <Tooltip
                    title={`${item.state.status.general}: Refreshed ${new Date(
                        item.metadata.timestamp
                    ).toLocaleTimeString("en-US", {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                    })}`}
                    placement="bottom-end"
                    enterTouchDelay={0}
                    arrow>
                    <div
                        className={`stateIndicator ${item.state.status.general.toLowerCase()}`}></div>
                </Tooltip>
            ) : (
                ""
            )}
        </div>
    );
}

export default Gameboard;
